/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/short-and-sweet@1.0.4/dist/short-and-sweet.min.js
 * - /npm/jquery-vjs-rails@1.2.2/src/rails.min.js
 * - /npm/bootstrap@4.1.3/dist/js/bootstrap.bundle.min.js
 * - /npm/plyr@3.7.8/dist/plyr.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/jquery-mask-plugin@1.14.16/dist/jquery.mask.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
